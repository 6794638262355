body {
    font-size: 0.875rem;
}

* {
    font-family: "Muli", sans-serif;
}

.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

/* 
Nav bar
*/
.an-navbar {
    height: var(--navbar-height);
}

#navbar-custom {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(2,2,2, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.an-navbar .navbar-brand {
    box-shadow: none !important;
}

.an-navbar .an-nav-avatar {
    height: 100%;
    width: 100%;
}

@media (max-width: 575.98px) {
    .an-navbar .an-nav-avatar {
        height: 52px;
        position: inherit;
        width: 66px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .an-navbar .an-nav-avatar {
        height: 66px;
        left: 3px;
        top: 3px;
        width: 66px;
    }
}

@media (min-width: 767px) and (max-width: 1024px) {
    .an-navbar .an-nav-avatar {
        height: 66px;
        left: 3px;
        top: 3px;
        width: 66px;
    }
}
@media (min-width: 768px) {
    .an-navbar .navbar-brand {
        width: 80px !important;
        max-width: 80px;
    }
}

/*
   * Sidebar
   */

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    /* Behind the navbar */
    padding: var(--navbar-height) 0 0;
    /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
    padding-right: 0px !important;
    padding-left: 0px !important;
    background-color: #152137;
    width: 80px;
}

@media (max-width: 768px) {
    .sidebar {
        background-color: transparent;
        width: 100%;
    }
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - var(--navbar-height));
    padding-top: 0rem;
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */
    background-color: #152137;
}

@media (min-width: 768px) {
    .sidebar-sticky::-webkit-scrollbar {
        width: 0px;
    }

    .sidebar-sticky::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 8px;
    }

    .sidebar-sticky::-webkit-scrollbar-thumb:hover {
        background: #546873;
    }

    .sidebar-sticky:-webkit-scrollbar-track {
        background: #eceaeafa;
    }
}

/* @media (min-width: 992px) and (max-width: 1199.98px) {
    .sidebar-sticky {
        padding-left: 0.75rem;
    }
} */
@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}

.sidebar .nav-link {
    font-weight: 500;
    color: #333;
}

.sidebar .nav-link .feather {
    margin-right: 4px;
    color: #999;
}

.sidebar .nav-link.active {
    color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
    color: inherit;
}

.sidebar-heading {
    font-size: 0.75rem;
    text-transform: uppercase;
}

/*
   * Content
   */
@media (max-width: 767px) {
    [role="main"] {
        padding-top: var(--navbar-height);
        /* Space for fixed navbar */
    }
}

@media (min-width: 768px) {
    [role="main"] {
        padding-top: var(--navbar-height);
        /* Space for fixed navbar */
        /* background-color: #152137; */
        margin-left: 80px;
    }
}

/*
   * Navbar
   */

.navbar-brand {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .form-control {
    padding: 0.75rem 1rem;
    border-width: 0;
    border-radius: 0;
}

.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

.sidebar-sticky .nav-item .nav-link {
    padding: 15px 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    --text-opacity: 1;
    color: #fff !important;
    position: relative;
}

.sidebar-sticky .nav-item .nav-link:hover .side-menu__icon svg {
    transform: scale(1.14);
}

.an-active-nav {
    background-color: #d15064;
    color: white !important;
}

.side-menu__icon svg {
    fill: white;
    width: 25px;
    height: 25px;
    transition: transform 0.2s;
}

/* .nav-item */
.side-menu__title {
    margin-top: 4px;
    font-size: 9px;
    text-transform: uppercase;
    font-weight: 400;
    text-align: center;
}

@media (max-width: 768px) {
    .sidebar-sticky .nav-item .nav-link {
        flex-direction: row;
    }

    .side-menu__title {
        margin-top: 0px;
        font-size: 14px;
        font-weight: 700;
        margin-left: 8px;
    }

    .side-menu__icon svg {
        width: 32px;
        height: 32px;
        margin-left: 10px;
    }
}

@media (min-width: 768px) {
    .nav-item {
        /* margin-bottom: 7px; */
    }
}

@keyframes collaps-animi {
    from {
        height: 0px;
    }

    to {
        height: calc(100vh - var(--navbar-height));
    }
}

@keyframes collaps-animi-revise {
    from {
        height: calc(100vh - var(--navbar-height));
    }

    to {
        height: 0px;
        padding: 0px;
    }
}

@media (max-width: 768px) {
    .sidebar-hidden {
        animation-name: sidebar-hidden;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
    }

    .sidebar-sticky-animi-revise {
        animation-name: collaps-animi-revise;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
    }

    .sidebar-sticky-animi {
        animation-name: collaps-animi;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
    }
}

@keyframes sidebar-hidden {
    from {
        display: block;
    }

    to {
        display: none;
        height: 0px;
    }
}

.an-profile-drop-down .dropdown-toggle:focus {
    border: none !important;
    box-shadow: none !important;
}

.an-profile-drop-down .dropdown-toggle::after {
    margin-top: 1px !important;
}

.an-profile-drop-down .dropdown-menu {
    top: 13px !important;
    width: 100%;
}