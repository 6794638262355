.info-icon {
    cursor: pointer;
    padding: 5px;
    padding-left: 10px;
}

/* info tooltip */
.info-tooltip {
    position: relative;
    display: inline-block;
  }
  
  .info-tooltip .hoverText {
    visibility: hidden;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .info-tooltip .hoverText::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 42%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .info-tooltip:hover .hoverText {
    visibility: visible;
    opacity: 1;
  }

/* info list tooltip */
.info-list-tooltip {
    position: relative;
    display: inline-block;
  }
  
  .info-list-tooltip .hoverText {
    visibility: hidden;
    width: 380px;
    background-color: #555;
    color: #fff;
    text-align: start;
    border-radius: 6px;
    padding: 10px 10px 0 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: -50%;
    margin-left: -125px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .info-list-tooltip .hoverText::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 42%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .info-list-tooltip:hover .hoverText {
    visibility: visible;
    opacity: 1;
  }