:root {
    --navbar-height: 64px;
    --info: #f76969 !important;
}

.custom-toast {
    border-radius: 10px;
}

/* custom info btn  */
.an-btn-info {
    background-color: var(--info) !important;
    border-color: transparent !important;
    border-radius: 5px !important;
}
.an-btn-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 105, 105, 0.66) !important;
}

/* info backgroud color */
.an-bg-info {
    background-color: var(--info);
}

/* info text color */
.an-bg-info {
    color: var(--info);
}

/* override bnt info */
.btn-info {
    color: #fff;
    background-color: var(--info) !important;
    border-color: var(--info) !important;
}
.btn-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 105, 105, 0.66) !important;
}
.background {
    height: calc(100vh - var(--navbar-height));
    overflow: auto;
    background-color: #f3f3f3;;
    scrollbar-width: thin;
}
@media (min-width: 767.98px) {
    .background::-webkit-scrollbar {
        width: 5px;
    }
    .background::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.288);
    }
}

.flow-header {
    min-height: 85px;
    z-index: 1020;
}
.flow-header-scroll {
    height: 100px;
}
.back-arrow svg {
    width: 31px;
    margin-left: -37px;
    padding-right: 6px;
    fill: white;
}
.back-arrow svg:hover {
    cursor: pointer;
    fill: gray;
}
@media (max-width: 768px) {
    .back-arrow svg {
        margin-left: 0px;
        margin-bottom: 4px;
        margin-top: 15px;
    }
}
@media (max-width: 768px) {
    .flow-header-scroll {
        height: 60px;
    }
}

.flow-header-step-hidden {
    display: none;
}
.flow-header-step-show {
    display: flex;
    color: rgb(135, 140, 147);
}
.flow-header-step-text {
    color: rgb(135, 140, 147);
}
.flow-header-title {
    font-family: "Muli", sans-serif;
    font-weight: 700;
}
@media (max-width: 768px) {
    .flow-header-title {
        font-size: 25px;
    }
}

/* custom flow container */
/* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 768px) {
    .flow-container {
        width: 100%;
        max-width: 100% !important;
    }
}

@media (min-width: 1200px) {
    .flow-container {
        width: 100%;
        max-width: 912px !important;
    }
}

.flow-wrapper {
    min-height: calc(100vh - 85px);
    background-color: #f2f2f7;
}
.flow-wrapper * {
    font-family: "Muli", sans-serif;
}
/* booking time selector style  */
.flow-wrapper-item {
    border-radius: 8px;
    background-color: white;
    box-shadow: rgba(16, 25, 40, 0.08) 0px 4px 8px 0px;
}
@media (max-width: 768px) {
    .flow-wrapper-item {
        border-radius: 0px;
        box-shadow: none;
    }
}

/* Toastify overides */

.Toastify__toast--default {
    background: #fff;
    color: #aaa;
}
.Toastify__toast--info {
    background: #3498db;
}
.Toastify__toast--success {
    background: #99b898; 
    color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 22px;
}
.Toastify__toast--warning {
    background: #f1935c;
}
.Toastify__toast--error {
    background: #ff847c; 
    color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 22px;
}

.skeleton-loading {
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: skeleton-animate 1.4s infinite;
    background-image: linear-gradient(112deg, #efefef 20%, #e7e7e7 30%, #efefef 38%, #d6d6d6 40%, #efefef 48%)
}

@keyframes skeleton-animate {
    100% {
        background-position: -100% 0;
    }
}