.dropdown-toggle::after {
    text-align: right;
    float: right;
    /*Chrome IE fix*/
    margin-top: 8px;
}

#add-appoint-modal #name {
    width: 75%;
}

#add-appoint-modal #phone {
    width: 50%;
}

.note-area {
    width: 100%;
}

.note-area::placeholder {
    text-align: center;
    padding-top: 30px;
}

.note-area:focus {
    outline: none !important;
    border: 1px soli;
}

/* Specific for small devices */

@media (max-width: 576px) {
    #add-appoint-modal #name,
    #add-appoint-modal #phone {
        width: 100%;
    }
}
.an_new_appointment_btn {
    background-color: #dd5467 !important;
    border-color: transparent !important;
    border-radius: 5px !important;
    transition: transform 1s;
}
.an_new_appointment_btn:hover {
    -webkit-box-shadow: 0px 0px 8px 0px #c32e2e; /* Android 2.3+, iOS 4.0.2-4.2, Safari 3-4 */
    box-shadow: 0px 0px 8px 0px #c32e2e; /* Chrome 6+, Firefox 4+, IE 9+, iOS 5+, Opera 10.50+ */
    transform: scale(1.01, 1.01);
}
.an_new_appointment_flot {
    left: calc(100vw - 100px) !important;
    bottom: 30px !important;
}
.an_new_appointment_flot_disabled {
    left: calc(100vw - 100px) !important;
    bottom: 30px !important;
    opacity: 0.6;
}
.an_new_appointment_flot_img {
    width: 60px;
    height: 60px;
    background-color: #f76969;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s;
}
.an_new_appointment_flot_img:hover {
    cursor: pointer;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    transform: scale(1.05, 1.05);
}
.an_new_appointment_flot_img svg {
    width: 30px;
    height: 30px;
    fill: white;
}
.an_new_appointment_flot_text {
    font-size: 13px;
    padding: 1px 6px 1px 6px;
    color: white;
    background-color: rgba(33, 37, 41, 0.541176);
    border-radius: 4px;
    margin-top: 3px;
}
.an_new_appointment_flot_text:hover {
    cursor: pointer;
}
.edit-button{
    padding-left: 70%;
}
.an_new_appointment_edit_btn {
    background-color: #949292 !important;
    border-color: transparent !important;
    border-radius: 5px !important;
    /* transition: transform 1s; */
}
.required {
    color: red;
}

.an_disable_booking_slot {
    background-color: #e7e7e7 !important;
    cursor: not-allowed !important;
}
