.an-active-nav {
    transition-delay: 0.2s;
    transition-duration: 0.2s;

    .side-menu__icon svg {
        fill: white;
    }

    .side-menu__title {
        color: white;
    }
}