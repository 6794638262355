@import './Style_keyframes.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.an-navbar {
    background-color: rgb(255, 255, 255);
    .dropdown-toggle::after {
        color: black;
    }
}

.text-nav {
    color: rgb(0, 0, 0);
}

.text-business-name{
    font-family: 'Inter', sans-serif;
    letter-spacing: 4px !important;
}

.an-profile-drop-down{
    color: black;
}

.sidebar-sticky {
    background-color: #ffffff;
    padding: 2px 4px;
    .nav-item{
        .nav-link {
            color: black !important;
            padding: 15px 0px;
        }
    } 
}

.side-menu__icon svg {
    fill: rgb(0, 0, 0);
}

.an-active-nav {
    border-radius: 15px
}
