@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
    font-size: 0.875rem;
}

* {
    font-family: "Muli", sans-serif;
}

.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

/* 
Nav bar
*/
.an-navbar {
    height: var(--navbar-height);
}

#navbar-custom {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(2,2,2, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.an-navbar .navbar-brand {
    box-shadow: none !important;
}

.an-navbar .an-nav-avatar {
    height: 100%;
    width: 100%;
}

@media (max-width: 575.98px) {
    .an-navbar .an-nav-avatar {
        height: 52px;
        position: inherit;
        width: 66px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .an-navbar .an-nav-avatar {
        height: 66px;
        left: 3px;
        top: 3px;
        width: 66px;
    }
}

@media (min-width: 767px) and (max-width: 1024px) {
    .an-navbar .an-nav-avatar {
        height: 66px;
        left: 3px;
        top: 3px;
        width: 66px;
    }
}
@media (min-width: 768px) {
    .an-navbar .navbar-brand {
        width: 80px !important;
        max-width: 80px;
    }
}

/*
   * Sidebar
   */

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    /* Behind the navbar */
    padding: var(--navbar-height) 0 0;
    /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
    padding-right: 0px !important;
    padding-left: 0px !important;
    background-color: #152137;
    width: 80px;
}

@media (max-width: 768px) {
    .sidebar {
        background-color: transparent;
        width: 100%;
    }
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - var(--navbar-height));
    padding-top: 0rem;
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */
    background-color: #152137;
}

@media (min-width: 768px) {
    .sidebar-sticky::-webkit-scrollbar {
        width: 0px;
    }

    .sidebar-sticky::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 8px;
    }

    .sidebar-sticky::-webkit-scrollbar-thumb:hover {
        background: #546873;
    }

    .sidebar-sticky:-webkit-scrollbar-track {
        background: #eceaeafa;
    }
}

/* @media (min-width: 992px) and (max-width: 1199.98px) {
    .sidebar-sticky {
        padding-left: 0.75rem;
    }
} */
@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}

.sidebar .nav-link {
    font-weight: 500;
    color: #333;
}

.sidebar .nav-link .feather {
    margin-right: 4px;
    color: #999;
}

.sidebar .nav-link.active {
    color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
    color: inherit;
}

.sidebar-heading {
    font-size: 0.75rem;
    text-transform: uppercase;
}

/*
   * Content
   */
@media (max-width: 767px) {
    [role="main"] {
        padding-top: var(--navbar-height);
        /* Space for fixed navbar */
    }
}

@media (min-width: 768px) {
    [role="main"] {
        padding-top: var(--navbar-height);
        /* Space for fixed navbar */
        /* background-color: #152137; */
        margin-left: 80px;
    }
}

/*
   * Navbar
   */

.navbar-brand {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .form-control {
    padding: 0.75rem 1rem;
    border-width: 0;
    border-radius: 0;
}

.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

.sidebar-sticky .nav-item .nav-link {
    padding: 15px 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    --text-opacity: 1;
    color: #fff !important;
    position: relative;
}

.sidebar-sticky .nav-item .nav-link:hover .side-menu__icon svg {
    transform: scale(1.14);
}

.an-active-nav {
    background-color: #d15064;
    color: white !important;
}

.side-menu__icon svg {
    fill: white;
    width: 25px;
    height: 25px;
    transition: transform 0.2s;
}

/* .nav-item */
.side-menu__title {
    margin-top: 4px;
    font-size: 9px;
    text-transform: uppercase;
    font-weight: 400;
    text-align: center;
}

@media (max-width: 768px) {
    .sidebar-sticky .nav-item .nav-link {
        flex-direction: row;
    }

    .side-menu__title {
        margin-top: 0px;
        font-size: 14px;
        font-weight: 700;
        margin-left: 8px;
    }

    .side-menu__icon svg {
        width: 32px;
        height: 32px;
        margin-left: 10px;
    }
}

@media (min-width: 768px) {
    .nav-item {
        /* margin-bottom: 7px; */
    }
}

@keyframes collaps-animi {
    from {
        height: 0px;
    }

    to {
        height: calc(100vh - var(--navbar-height));
    }
}

@keyframes collaps-animi-revise {
    from {
        height: calc(100vh - var(--navbar-height));
    }

    to {
        height: 0px;
        padding: 0px;
    }
}

@media (max-width: 768px) {
    .sidebar-hidden {
        animation-name: sidebar-hidden;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
    }

    .sidebar-sticky-animi-revise {
        animation-name: collaps-animi-revise;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
    }

    .sidebar-sticky-animi {
        animation-name: collaps-animi;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
    }
}

@keyframes sidebar-hidden {
    from {
        display: block;
    }

    to {
        display: none;
        height: 0px;
    }
}

.an-profile-drop-down .dropdown-toggle:focus {
    border: none !important;
    box-shadow: none !important;
}

.an-profile-drop-down .dropdown-toggle::after {
    margin-top: 1px !important;
}

.an-profile-drop-down .dropdown-menu {
    top: 13px !important;
    width: 100%;
}
.dropdown-toggle::after {
    text-align: right;
    float: right;
    /*Chrome IE fix*/
    margin-top: 8px;
}

#add-appoint-modal #name {
    width: 75%;
}

#add-appoint-modal #phone {
    width: 50%;
}

.note-area {
    width: 100%;
}

.note-area::placeholder {
    text-align: center;
    padding-top: 30px;
}

.note-area:focus {
    outline: none !important;
    border: 1px soli;
}

/* Specific for small devices */

@media (max-width: 576px) {
    #add-appoint-modal #name,
    #add-appoint-modal #phone {
        width: 100%;
    }
}
.an_new_appointment_btn {
    background-color: #dd5467 !important;
    border-color: transparent !important;
    border-radius: 5px !important;
    transition: transform 1s;
}
.an_new_appointment_btn:hover { /* Android 2.3+, iOS 4.0.2-4.2, Safari 3-4 */
    box-shadow: 0px 0px 8px 0px #c32e2e; /* Chrome 6+, Firefox 4+, IE 9+, iOS 5+, Opera 10.50+ */
    transform: scale(1.01, 1.01);
}
.an_new_appointment_flot {
    left: calc(100vw - 100px) !important;
    bottom: 30px !important;
}
.an_new_appointment_flot_disabled {
    left: calc(100vw - 100px) !important;
    bottom: 30px !important;
    opacity: 0.6;
}
.an_new_appointment_flot_img {
    width: 60px;
    height: 60px;
    background-color: #f76969;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s;
}
.an_new_appointment_flot_img:hover {
    cursor: pointer;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    transform: scale(1.05, 1.05);
}
.an_new_appointment_flot_img svg {
    width: 30px;
    height: 30px;
    fill: white;
}
.an_new_appointment_flot_text {
    font-size: 13px;
    padding: 1px 6px 1px 6px;
    color: white;
    background-color: rgba(33, 37, 41, 0.541176);
    border-radius: 4px;
    margin-top: 3px;
}
.an_new_appointment_flot_text:hover {
    cursor: pointer;
}
.edit-button{
    padding-left: 70%;
}
.an_new_appointment_edit_btn {
    background-color: #949292 !important;
    border-color: transparent !important;
    border-radius: 5px !important;
    /* transition: transform 1s; */
}
.required {
    color: red;
}
.info-icon {
    cursor: pointer;
    padding: 5px;
    padding-left: 10px;
}

/* info tooltip */
.info-tooltip {
    position: relative;
    display: inline-block;
  }
  
  .info-tooltip .hoverText {
    visibility: hidden;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .info-tooltip .hoverText::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 42%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .info-tooltip:hover .hoverText {
    visibility: visible;
    opacity: 1;
  }

/* info list tooltip */
.info-list-tooltip {
    position: relative;
    display: inline-block;
  }
  
  .info-list-tooltip .hoverText {
    visibility: hidden;
    width: 380px;
    background-color: #555;
    color: #fff;
    text-align: start;
    border-radius: 6px;
    padding: 10px 10px 0 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: -50%;
    margin-left: -125px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .info-list-tooltip .hoverText::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 42%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .info-list-tooltip:hover .hoverText {
    visibility: visible;
    opacity: 1;
  }
.dropdown-toggle::after {
    text-align: right;
    float: right;
    /*Chrome IE fix*/
    margin-top: 8px;
}

#add-appoint-modal #name {
    width: 75%;
}

#add-appoint-modal #phone {
    width: 50%;
}

.note-area {
    width: 100%;
}

.note-area::placeholder {
    text-align: center;
    padding-top: 30px;
}

.note-area:focus {
    outline: none !important;
    border: 1px soli;
}

/* Specific for small devices */

@media (max-width: 576px) {
    #add-appoint-modal #name,
    #add-appoint-modal #phone {
        width: 100%;
    }
}
.an_new_appointment_btn {
    background-color: #dd5467 !important;
    border-color: transparent !important;
    border-radius: 5px !important;
    transition: transform 1s;
}
.an_new_appointment_btn:hover { /* Android 2.3+, iOS 4.0.2-4.2, Safari 3-4 */
    box-shadow: 0px 0px 8px 0px #c32e2e; /* Chrome 6+, Firefox 4+, IE 9+, iOS 5+, Opera 10.50+ */
    transform: scale(1.01, 1.01);
}
.an_new_appointment_flot {
    left: calc(100vw - 100px) !important;
    bottom: 30px !important;
}
.an_new_appointment_flot_disabled {
    left: calc(100vw - 100px) !important;
    bottom: 30px !important;
    opacity: 0.6;
}
.an_new_appointment_flot_img {
    width: 60px;
    height: 60px;
    background-color: #f76969;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s;
}
.an_new_appointment_flot_img:hover {
    cursor: pointer;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    transform: scale(1.05, 1.05);
}
.an_new_appointment_flot_img svg {
    width: 30px;
    height: 30px;
    fill: white;
}
.an_new_appointment_flot_text {
    font-size: 13px;
    padding: 1px 6px 1px 6px;
    color: white;
    background-color: rgba(33, 37, 41, 0.541176);
    border-radius: 4px;
    margin-top: 3px;
}
.an_new_appointment_flot_text:hover {
    cursor: pointer;
}
.edit-button{
    padding-left: 70%;
}
.an_new_appointment_edit_btn {
    background-color: #949292 !important;
    border-color: transparent !important;
    border-radius: 5px !important;
    /* transition: transform 1s; */
}
.required {
    color: red;
}

.an_disable_booking_slot {
    background-color: #e7e7e7 !important;
    cursor: not-allowed !important;
}

.an-active-nav{transition-delay:0.2s;transition-duration:0.2s}.an-active-nav .side-menu__icon svg{fill:white}.an-active-nav .side-menu__title{color:white}.an-navbar{background-color:#fff}.an-navbar .dropdown-toggle::after{color:black}.text-nav{color:#000}.text-business-name{font-family:'Inter', sans-serif;letter-spacing:4px !important}.an-profile-drop-down{color:black}.sidebar-sticky{background-color:#ffffff;padding:2px 4px}.sidebar-sticky .nav-item .nav-link{color:black !important;padding:15px 0px}.side-menu__icon svg{fill:#000}.an-active-nav{border-radius:15px}

:root {
    --navbar-height: 64px;
    --info: #f76969 !important;
}

.custom-toast {
    border-radius: 10px;
}

/* custom info btn  */
.an-btn-info {
    background-color: var(--info) !important;
    border-color: transparent !important;
    border-radius: 5px !important;
}
.an-btn-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 105, 105, 0.66) !important;
}

/* info backgroud color */
.an-bg-info {
    background-color: var(--info);
}

/* info text color */
.an-bg-info {
    color: var(--info);
}

/* override bnt info */
.btn-info {
    color: #fff;
    background-color: var(--info) !important;
    border-color: var(--info) !important;
}
.btn-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 105, 105, 0.66) !important;
}
.background {
    height: calc(100vh - var(--navbar-height));
    overflow: auto;
    background-color: #f3f3f3;;
    scrollbar-width: thin;
}
@media (min-width: 767.98px) {
    .background::-webkit-scrollbar {
        width: 5px;
    }
    .background::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.288);
    }
}

.flow-header {
    min-height: 85px;
    z-index: 1020;
}
.flow-header-scroll {
    height: 100px;
}
.back-arrow svg {
    width: 31px;
    margin-left: -37px;
    padding-right: 6px;
    fill: white;
}
.back-arrow svg:hover {
    cursor: pointer;
    fill: gray;
}
@media (max-width: 768px) {
    .back-arrow svg {
        margin-left: 0px;
        margin-bottom: 4px;
        margin-top: 15px;
    }
}
@media (max-width: 768px) {
    .flow-header-scroll {
        height: 60px;
    }
}

.flow-header-step-hidden {
    display: none;
}
.flow-header-step-show {
    display: flex;
    color: rgb(135, 140, 147);
}
.flow-header-step-text {
    color: rgb(135, 140, 147);
}
.flow-header-title {
    font-family: "Muli", sans-serif;
    font-weight: 700;
}
@media (max-width: 768px) {
    .flow-header-title {
        font-size: 25px;
    }
}

/* custom flow container */
/* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 768px) {
    .flow-container {
        width: 100%;
        max-width: 100% !important;
    }
}

@media (min-width: 1200px) {
    .flow-container {
        width: 100%;
        max-width: 912px !important;
    }
}

.flow-wrapper {
    min-height: calc(100vh - 85px);
    background-color: #f2f2f7;
}
.flow-wrapper * {
    font-family: "Muli", sans-serif;
}
/* booking time selector style  */
.flow-wrapper-item {
    border-radius: 8px;
    background-color: white;
    box-shadow: rgba(16, 25, 40, 0.08) 0px 4px 8px 0px;
}
@media (max-width: 768px) {
    .flow-wrapper-item {
        border-radius: 0px;
        box-shadow: none;
    }
}

/* Toastify overides */

.Toastify__toast--default {
    background: #fff;
    color: #aaa;
}
.Toastify__toast--info {
    background: #3498db;
}
.Toastify__toast--success {
    background: #99b898; 
    color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 22px;
}
.Toastify__toast--warning {
    background: #f1935c;
}
.Toastify__toast--error {
    background: #ff847c; 
    color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 22px;
}

.skeleton-loading {
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: skeleton-animate 1.4s infinite;
    background-image: linear-gradient(112deg, #efefef 20%, #e7e7e7 30%, #efefef 38%, #d6d6d6 40%, #efefef 48%)
}

@keyframes skeleton-animate {
    100% {
        background-position: -100% 0;
    }
}
